<template>
 <div v-html='apply'>
     {{apply}}
  </div>
</template>

<script>
export default {
  name: 'applyText',
  data () {
    return {
      apply: ''
    }
  },
  created () {
    const from = this.$route.query.htmls
    this.apply = from
  },
  mounted () {
    this.$nextTick(res => {
      document.forms[0].submit()
    })
  }
}
</script>

<style>

</style>
